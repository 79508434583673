// components/ProtectedRoute.js
import React from 'react';
import { Navigate } from 'react-router-dom';
import useAuth from './useAuth';


const ProtectedRoute = ({ element: Component }) => {
  const isAuthenticated = useAuth();

  if (isAuthenticated === null) {
    // Optionally, you can show a loading spinner or a placeholder while checking authentication status
    return <div>Loading...</div>;
  }

  return isAuthenticated ? <Component /> : <Navigate to="/" />;
};

export default ProtectedRoute;

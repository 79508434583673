// hooks/useAuth.js
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

const useAuth = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const loginStatus = localStorage.getItem("isLogin") === "true";
    setIsAuthenticated(loginStatus);

    if (!loginStatus) {
      navigate('/');
    }
  }, [navigate]);

  return isAuthenticated;
};

export default useAuth;

import axios from "axios";
import API_URL from "../Configuration/Configuration.js";

class AdminService {
  async register(username, password) {
    const response = await axios.post(`${API_URL}/admin/register`, {
      username,
      password,
    });
    return response.data;
  }

  async login(username, password) {
    const response = await axios.post(`${API_URL}/admin/login`, {
      username,
      password,
    });

    return response.data;
  }
}

const adminServiceInstance = new AdminService();

export default adminServiceInstance;

import React, { useEffect, useState } from "react";
import userAuthService from "../Services/userAuthService";

function CardsValues() {
  const [cardValues, setcardValues] = useState([]);

  const fetchDashboardValues = async () => {
    try {
      const response = await userAuthService.getAllViolationsCount();
      console.log("aaaaaaaaaaaaaaa" + response);
      if (response && response.data && response.data.length > 0) {
        setcardValues(response.data[0]);
      } else {
        console.error("No data found in the response.");
      }
    } catch (error) {
      console.error("Error fetching dashboard values", error);
    }
  };

  useEffect(() => {
    fetchDashboardValues();
  }, []);

  return (
    <div className="Container CardValues mt-2">
      {cardValues && (
        <div className="row m-1 cardsData">
          <div className="col-xl-3 col-md-6 col-sm-12 mt-1">
            <div className="card">
              <div className="card-body issues">
                <h5 className="card-title">{cardValues.allVoilations}</h5>
                <h6 className="card-subtitle mb-2 text-body-secondary">
                  Total Number of Issues
                </h6>
              </div>
            </div>
          </div>
          <div className="col-xl-3 col-md-6 col-sm-12 mt-1">
            <div className="card">
              <div className="card-body pendingissues">
                <h5 className="card-title">{cardValues.pendingCount}</h5>
                <h6 className="card-subtitle mb-2 text-body-secondary">
                  Total Pending Issues
                </h6>
              </div>
            </div>
          </div>

          <div className="col-xl-3 col-md-6 col-sm-12 mt-1">
            <div className="card">
              <div className="card-body Approvedissues">
                <h5 className="card-title">{cardValues.approvedCount}</h5>
                <h6 className="card-subtitle mb-2 text-body-secondary">
                  Total Approved Issues
                </h6>
              </div>
            </div>
          </div>
          <div className="col-xl-3 col-md-6 col-sm-12 mt-1">
            <div className="card">
              <div className="card-body Rejectedissues">
                <h5 className="card-title">{cardValues.rejectedCount}</h5>
                <h6 className="card-subtitle mb-2 text-body-secondary">
                  Total Rejected Issues
                </h6>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default CardsValues;

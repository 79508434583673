import axios from 'axios';
import API_URL from '../Configuration/Configuration';
 // Replace with your actual API base URL

const userViolationService = {
  postViolation: async (violationData) => {
    try {
      const response = await axios.post(`${API_URL}/postViolation`, violationData);
      return response.data;
    } catch (error) {
      console.error('Error posting violation:', error);
      throw error;
    }
  },

  getAllViolations: async () => {
    try {
      const response = await axios.get(`${API_URL}/getAllViolations`);
      return response.data;
    } catch (error) {
      console.error('Error fetching all violations:', error);
      throw error;
    }
  },

  getUserAllViolations: async (userId) => {
    try {
      const response = await axios.get(`${API_URL}/getUserAllViolations`, {
        params: { userId }
      });
      return response.data;
    } catch (error) {
      console.error('Error fetching user violations:', error);
      throw error;
    }
  },

  getUserViolation: async (userId, status) => {
    try {
      const response = await axios.post(`${API_URL}/getUserViolation`, {
        userId,
        status
      });
      return response.data;
    } catch (error) {
      console.error('Error fetching user violation by status:', error);
      throw error;
    }
  },

  updateViolationStatus: async (updateData) => {
    try {
      const response = await axios.post(`${API_URL}/violation/updateViolationStatus`, updateData);
      return response.data;
    } catch (error) {
      console.error('Error updating violation status:', error);
      throw error;
    }
  },

  getFilteredViolations: async (status) => {
    try {
      const response = await axios.get(`${API_URL}/getFilteredViolations`, {
        params: { status }
      });
      return response.data;
    } catch (error) {
      console.error('Error fetching filtered violations:', error);
      throw error;
    }
  }
};

export default userViolationService;

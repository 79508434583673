// import * as React from "react";
import React, { useEffect } from "react";
import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import logo from "../images/log.png";
import { Avatar, ListItemAvatar, Menu, MenuItem } from "@mui/material";
import SidebarProfile from "../images/SidebarProfile.png";
import { Link, useNavigate } from "react-router-dom";
import {
  AccountCircle,
  CarCrashRounded,
  EmojiEventsRounded,
} from "@mui/icons-material";
import { ToastContainer, toast, Zoom } from "react-toastify";

const drawerWidth = 240;

function SideNavbar(props) {
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [isClosing, setIsClosing] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [auth, setAuth] = React.useState(true);
  const navigate = useNavigate();

  const handleDrawerClose = () => {
    setIsClosing(true);
    setMobileOpen(false);
  };

  const handleDrawerTransitionEnd = () => {
    setIsClosing(false);
  };

  const handleDrawerToggle = () => {
    if (!isClosing) {
      setMobileOpen(!mobileOpen);
    }
  };

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handlelogout = () => {
    localStorage.setItem("isLogin", false);
    console.log(setAuth);
    toast.info("Logout Successfully.......", {
      position: "top-right",
      autoClose: 1200,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
      transition: Zoom,
    });
    setTimeout(() => {
      navigate("/");
    }, 1700);
    setAnchorEl(null);
  };

  useEffect(()=>{
    console.log(setAuth);
  },[])

  const drawer = (
    <div>
      <Divider />
      <Box
        sx={{
          width: "100%",
          height: 100,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
        component={Link}
        to="/layout_page/subdashboard_page"
      >
        <img
          src={logo}
          alt="Network Error"
          style={{ maxWidth: "100%", maxHeight: "100%" }}
        />
      </Box>
      <ListItem alignItems="flex-start" className="profileImage">
        <ListItemAvatar>
          <Avatar
            alt="Network Error"
            src={SidebarProfile}
            sx={{
              width: { xs: 36, sm: 46, md: 56, lg: 55 },
              height: { xs: 36, sm: 46, md: 56, lg: 55 },
            }}
          />
        </ListItemAvatar>
        <ListItemText
          primary="Admin ID: 12345678"
          className="mt-4 text-light"
        />
      </ListItem>
      <ListItem disablePadding>
        <ListItemButton component={Link} to="/layout_page/Violation_page">
          <ListItemIcon>
            <CarCrashRounded />
          </ListItemIcon>
          <ListItemText primary="Violation Type" className="text-primary" />
        </ListItemButton>
      </ListItem>
      <ListItem disablePadding>
        <ListItemButton component={Link} to="/layout_page/Rewards_page">
          <ListItemIcon>
            <EmojiEventsRounded />
          </ListItemIcon>
          <ListItemText primary="Reward Points" className="text-primary" />
        </ListItemButton>
      </ListItem>
      <Divider />
    </div>
  );

  // Remove this const when copying and pasting into your project.
  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` },
        }}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: "none" } }}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap component="div">
            Dashboard
          </Typography>

          {auth && (
            <div style={{ marginLeft: "auto" }}>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleMenu}
                color="inherit"
              >
                <AccountCircle />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                <MenuItem onClick={handleClose}>Profile</MenuItem>
                <MenuItem onClick={handlelogout}>Logout</MenuItem>
              </Menu>
            </div>
          )}
        </Toolbar>
        <ToastContainer />
      </AppBar>
      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onTransitionEnd={handleDrawerTransitionEnd}
          onClose={handleDrawerClose}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: "none", sm: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 2,
          width: { sm: `calc(100% - ${drawerWidth}px)` },
        }}
      >
        <Toolbar />
      </Box>
    </Box>
  );
}

SideNavbar.propTypes = {
  window: PropTypes.func,
};

export default SideNavbar;

// src/services/violationService.js
import axios from "axios";
import BASE_URL from "../Configuration/Configuration";


const postViolation = async (violationData) => {
  try {
    const response = await axios.post(`${BASE_URL}/violation/postViolation`, violationData);
    return response.data;
  } catch (error) {
    console.error("Error posting violation:", error);
    throw error;
  }
};

const getAllViolations = async () => {
  try {
    const response = await axios.get(`${BASE_URL}/violation/getAllViolations`);
    return response.data;
  } catch (error) {
    console.error("Error fetching all violations:", error);
    throw error;
  }
};

const getUserAllViolations = async (userId) => {
  try {
    const response = await axios.get(`${BASE_URL}/violation/getUserAllViolations/${userId}`);
    return response.data;
  } catch (error) {
    console.error("Error fetching user all violations:", error);
    throw error;
  }
};

const getViolationById = async (userVoilationId) => {
  try {
    const response = await axios.get(`${BASE_URL}/violation/getVoilationById/${userVoilationId}`);
    return response.data;
  } catch (error) {
    console.error("Error fetching user all violations:", error);
    throw error;
  }
};

const getUserViolation = async (userId, status) => {
  try {
    const response = await axios.post(`${BASE_URL}/violation/getUserViolation`, {
      userId,
      status,
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching user violation:", error);
    throw error;
  }
};

const updateViolationStatus = async (violationStatusData) => {
  try {
    const response = await axios.post(`${BASE_URL}/violation/updateViolationStatus`, violationStatusData);
    return response.data;
  } catch (error) {
    console.error("Error updating violation status:", error);
    throw error;
  }
};

const getFilteredViolations = async (status) => {
  try {
    const response = await axios.get(`${BASE_URL}/violation/getFilteredViolations`, {
      params: { status }
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching filtered violations:", error);
    throw error;
  }
};

export {
  postViolation,
  getAllViolations,
  getUserAllViolations,
  getUserViolation,
  updateViolationStatus,
  getFilteredViolations,
  getViolationById,
};

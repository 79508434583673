import React from "react";
import { Route, Routes } from "react-router-dom";

import ViolationTypePage from "./ViolationTypePage";
import DashboardPage from "./DashboardPage";
import RewardPoints from "./RewardPoints";
import SideNavbar from "./SideNavbar";
import SubDashboard from "./SubDashboard";

function LayoutPage() {
  return (
    <div className="layout">
      <div className="row">
        <div className="col-sm-12">
          <SideNavbar />
        </div>
      </div>
      <Routes>
        <Route path="/dashboard_page" element={<DashboardPage />} />
        <Route path="/subdashboard_page" element={<SubDashboard />} />
        <Route path="/Violation_page" element={<ViolationTypePage />} />
        <Route path="/Rewards_page" element={<RewardPoints />} />
        <Route path="/subdashboard_page" element={<SubDashboard />} />
        <Route path="/sideNavbar_page" element={<SideNavbar />} />
      </Routes>
    </div>
  );
}

export default LayoutPage;

import axios from "axios";
import API_URL from "../Configuration/Configuration";

const violationTypeService = {
  getViolationTypes: async () => {
    try {
      const response = await axios.get(`${API_URL}/violationType/getViolationTypes`);
      return response.data;
    } catch (error) {
      console.error("Error fetching violation types:", error);
      throw error;
    }
  },

  createViolationType: async (violationTypeData) => {
    try {
      const response = await axios.post(
        `${API_URL}/violationType/createViolationType`,
        violationTypeData
      );
      return response.data;
    } catch (error) {
      console.error("Error creating violation type:", error);
      throw error;
    }
  },

  updateViolationType: async (violationTypeData) => {
    try {
      const response = await axios.post(
        `${API_URL}/ViolationType/updateViolationType`,
        violationTypeData
      );
      return response.data;
    } catch (error) {
      console.error("Error updating violation type:", error);
      throw error;
    }
  },

  deleteViolationType: async (violationTypeId) => {
    try {
      const response = await axios.delete(`${API_URL}/violationType/deleteViolationType`, {
        data: { violationTypeId },
      });
      console.log("aaaaaaaaaaaaaaaaaaaaa"+response.data);
      return response.data;
    } catch (error) {
      console.error("Error deleting violation type:", error);
      throw error;
    }
  },
};

export default violationTypeService;

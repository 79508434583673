import axios from "axios";
import API_BASE_URL from "../Configuration/Configuration";

const userAuthService = {
  sendEmailOtp: async (email) => {
    try {
      const response = await axios.post(`${API_BASE_URL}/emailOtp`, { email });
      return response.data;
    } catch (error) {
      throw error.response ? error.response.data : error;
    }
  },

  verifyEmailOtp: async (email, password) => {
    try {
      const response = await axios.post(`${API_BASE_URL}/verifyEmail`, {
        email,
        password,
      });
      return response.data;
    } catch (error) {
      throw error.response ? error.response.data : error;
    }
  },

  sendMobileOtp: async (mobile) => {
    try {
      const response = await axios.post(`${API_BASE_URL}/mobileOtp`, {
        mobile,
      });
      return response.data;
    } catch (error) {
      throw error.response ? error.response.data : error;
    }
  },

  verifyMobileOtp: async (mobile, password) => {
    try {
      const response = await axios.post(`${API_BASE_URL}/verifyMobile`, {
        mobile,
        password,
      });
      return response.data;
    } catch (error) {
      throw error.response ? error.response.data : error;
    }
  },

  getAllViolationsCount: async () => {
    try {
      const response = await axios.get(
        `${API_BASE_URL}/user/getAllVoliationsCount`
      );
      return response.data;
    } catch (error) {
      throw error.response ? error.response.data : error;
    }
  },
};

export default userAuthService;

import React, { useCallback, useEffect, useState } from "react";
import $ from "jquery";
import "datatables.net-bs5";
import { faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import violationTypeService from "../Services/violationTypeService";
import CardsValues from "./CardsValues";
import Modal from "react-bootstrap/Modal";
import { Box, CircularProgress } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import Button from "@mui/material/Button";
import { CancelOutlined, CarCrashRounded } from "@mui/icons-material";
import HomeIcon from "@mui/icons-material/Home";
import { Breadcrumbs, Link, Stack } from "@mui/material";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast, Zoom } from "react-toastify";

function ViolationTypePage() {
  const [tableData, setTableData] = useState([]);
  const [show, setShow] = useState(false);
  const [successModalShow, setSuccessModalShow] = useState(false);
  const [violationTypedata, setViolationTypeData] = useState("");
  const [selectedViolationTypeId, setSelectedViolationTypeId] = useState("");
  const [selectedViolationType, setSelectedViolationType] = useState("");
  const [showUpdateCard, setShowUpdateCard] = useState(false);
  const [showAddCard, setShowAddCard] = useState(true);
  const [deleteViolationTypeId, setdeleteViolationTypeId] = useState();
  const [isLoading, setIsLoading] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = (e) => {
    setdeleteViolationTypeId(e);
    setShow(true);
  };

  const fetchData = useCallback(() => {
    const fetchDataAsync = async () => {
      try {
        const response = await violationTypeService.getViolationTypes();
        setTableData(response.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchDataAsync();
  }, []);

  useEffect(() => {
    fetchData();

    return () => {
      localStorage.removeItem("token");
    };
  }, [fetchData]);

  const createViolationType = async () => {
    setIsLoading(true);
    try {
      const result = await violationTypeService.createViolationType({
        name: violationTypedata,
      });
      if (result) {
        setTableData((prevTableData) => [...prevTableData, result.data]);

        toast.success("Violation Type added successfully!", {
          position: "top-right",
          autoClose: 1500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
          transition: Zoom,
        });
        setViolationTypeData(""); // Clear input field after adding
        fetchData();
      } else {
        console.error("Creation failed:", result.message);
      }
    } catch (error) {
      if (
        error.response &&
        error.response.status === 400 &&
        error.response.data.message === "Violation Type already exists"
      ) {
      } else {
        console.error("Error creating violation type:", error);
        toast.error("Violation Type already exists", {
          position: "top-right",
          autoClose: 1500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
          transition: Zoom,
        });
      }
    } finally {
      setIsLoading(false); // Hide loader after creating violation type
    }
  };

  const updateViolationType = async () => {
    try {
      await violationTypeService.updateViolationType({
        violationTypeId: selectedViolationTypeId,
        name: selectedViolationType,
      });
      fetchData();
      setShowUpdateCard(false); // Hide update card after update
      setShowAddCard(true); // Show Add functionality div
      setSuccessModalShow(true); // Show success modal
      toast.success("Violation Type updated successfully!", {
        position: "top-right",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        transition: Zoom,
      });
    } catch (error) {
      console.error("Error updating violation type:", error, successModalShow);
      toast.error("Error updating violation type", {
        position: "top-right",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        transition: Zoom,
      });
    } finally {
      setIsLoading(false); // Hide loader after creating violation type
    }
  };

  const handleEditViolationType = (violationTypeId, violationType) => {
    setSelectedViolationTypeId(violationTypeId);
    setSelectedViolationType(violationType);
    setShowUpdateCard(true); // Show update card
    setShowAddCard(false); // Hide Add functionality div
  };

  useEffect(() => {
    let table;
    if (tableData.length > 0) {
      table = $("#myTable").DataTable();
    }
    return () => {
      if (table) {
        table.destroy();
      }
    };
  }, [tableData]);

  const deleteviolatioType = async () => {
    setIsLoading(true);
    try {
      const response = await violationTypeService.deleteViolationType(
        deleteViolationTypeId
      );
      if (response) {
        fetchData();
        toast.success("Violation Type deleted successfully!..", {
          position: "top-right",
          autoClose: 1300,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
          transition: Zoom,
        });
      } else {
        console.error("Deletion failed:", response.message);
      }
      setShow(false);
    } catch (error) {
      console.error("Error deleting violation type:", error);
      toast.info("Error deleting violation type!..", {
        position: "top-right",
        autoClose: 1300,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        transition: Zoom,
      });
    } finally {
      setIsLoading(false); // Hide loader after creating violation type
    }
  };

  return (
    <div className="Container ViolationTypePage">
      <ToastContainer />
      <div className="breadcrumbs ms-3">
        <Breadcrumbs aria-label="breadcrumb">
          <Link
            underline="none"
            sx={{ display: "flex", alignItems: "center" }}
            color="inherit"
          >
            <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
            Trrafic Sudharo
          </Link>
          <Link
            underline="none"
            sx={{ display: "flex", alignItems: "center" }}
            color="inherit"
          >
            <CarCrashRounded sx={{ mr: 0.5 }} fontSize="inherit" />
            Violation Type
          </Link>
        </Breadcrumbs>
      </div>

      <div className="row">
        <CardsValues />
      </div>

      {showAddCard && (
        <div className="card m-3 createviolationCard">
          <div className="card-body">
            <div className="row">
              <div className="col-xl-6">
                <div className="mb-0">
                  <label
                    htmlFor="formGroupExampleInput"
                    className="form-label ms-1"
                  >
                    Violation Type
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="formGroupExampleInput"
                    placeholder="Violation Type"
                    value={violationTypedata}
                    onChange={(e) => setViolationTypeData(e.target.value)}
                  />
                </div>
              </div>

              <div className="col-xl-6 addbtn mt-4">
                <button
                  type="button"
                  className="btn btn-warning"
                  onClick={createViolationType}
                  disabled={!violationTypedata}
                >
                  Add
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {showUpdateCard && (
        <div className="card m-3 updateviolationCard">
          <div className="card-body">
            <div className="row">
              <div className="col-xl-4">
                <div className="mb-0">
                  <label
                    htmlFor="violationTypeIdInput"
                    className="form-label ms-1"
                  >
                    Violation Type Id
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="violationTypeIdInput"
                    value={selectedViolationTypeId}
                    readOnly
                  />
                </div>
              </div>
              <div className="col-xl-4">
                <div className="mb-0">
                  <label
                    htmlFor="violationTypeInput"
                    className="form-label ms-1"
                  >
                    Violation Type
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="violationTypeInput"
                    value={selectedViolationType}
                    onChange={(e) => setSelectedViolationType(e.target.value)}
                  />
                </div>
              </div>
              <div className="col-xl-2 addbtn mt-4">
                <button
                  type="button"
                  className="btn btn-warning"
                  onClick={updateViolationType}
                >
                  Update
                </button>
              </div>
              <div className="col-xl-2 addbtn mt-4">
                <button
                  type="button"
                  className="btn btn-warning"
                  onClick={() => {
                    setShowUpdateCard(false);
                    setShowAddCard(true);
                  }}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {isLoading ? (
        <div className="loader-container">
          <CircularProgress />
        </div>
      ) : (
        <div className="card m-3">
          <div className="card-body">
            <table
              id="myTable"
              className="table table-striped"
              style={{ width: "100%" }}
            >
              <thead>
                <tr>
                  <th className="text-center">Id</th>
                  <th className="text-center">Violation Type</th>
                  <th className="text-center">Status</th>
                </tr>
              </thead>
              <tbody>
                {tableData.map((row, index) => (
                  <tr key={index}>
                    <td className="center-cell text-center">{index + 1}</td>
                    <td className="text-center">{row.name}</td>
                    <td>
                      <div className="d-flex justify-content-center align-items-center">
                        <FontAwesomeIcon
                          icon={faEdit}
                          className="FontAwesomeIcons me-2 text-primary"
                          onClick={() =>
                            handleEditViolationType(
                              row.violationTypeId,
                              row.name
                            )
                          }
                        />
                        <FontAwesomeIcon
                          icon={faTrash}
                          className="FontAwesomeIcons ms-1 text-danger"
                          onClick={(e) => handleShow(row.violationTypeId)}
                        />
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      )}

      <Modal show={show} onHide={handleClose} centered>
        <Modal.Body>
          <h3 className="text-center">Are you sure want to delete</h3>
          <Box display="flex" justifyContent="center" alignItems="center">
            <Stack direction="row" spacing={2}>
              <Button
                variant="contained"
                color="error"
                startIcon={<DeleteIcon />}
                onClick={deleteviolatioType}
              >
                Delete
              </Button>
              <Button
                variant="contained"
                color="primary"
                endIcon={<CancelOutlined />}
                onClick={handleClose}
              >
                Close
              </Button>
            </Stack>
          </Box>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default ViolationTypePage;

import React, { useEffect, useRef, useState } from "react";
import $ from "jquery";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
import CardsValues from "./CardsValues";
import rewardService from "../Services/rewardService";
import HomeIcon from "@mui/icons-material/Home";
import {
  Box,
  Breadcrumbs,
  Button,
  CircularProgress,
  Link,
  Stack,
} from "@mui/material";
import { EmojiEventsRounded } from "@mui/icons-material";
import Modal from "react-bootstrap/Modal";
import DeleteIcon from "@mui/icons-material/Delete";
import { CancelOutlined } from "@mui/icons-material";
import violationTypeService from "../Services/violationTypeService";

function RewardPoints() {
  const [tableData, setTableData] = useState([]);
  const [show, setShow] = useState(false);
  const [deleteViolationTypeId, setdeleteViolationTypeId] = useState();
  // const [modalShow, setModalShow] = useState(false);
  const [violationType, setViolationType] = useState();
  const [rewardPoints, setRewardPoints] = useState();
  const [rewardId, setrewardId] = useState();
  const [isUpdateMode, setIsUpdateMode] = useState(false);
  const [createViolationType, setcreateViolationType] = useState();
  const [violations, setViolations] = useState([]);
  const [selectedViolationTypeId, setSelectedViolationTypeId] = useState("");
  const [createRewardPoint, setCreateRewardPoint] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const formRef = useRef(null);

  const fetchData = async () => {
    try {
      const response = await rewardService.getBaseRewards();

      if (response && Array.isArray(response.data)) {
        setTableData(response.data);
      } else {
        console.error("Fetched data is not an array:", response);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const getAllViolationTypes = async () => {
    try {
      // const response = await violationTypeService.getViolationTypes();
      await violationTypeService.getViolationTypes().then((data) => {
        console.log("aaaaaaaaaaaaaaaaaa" + data.data);
        if (Array.isArray(data.data)) {
          data.data.forEach((violation, index) => {
            if (violation.hasOwnProperty("name")) {
              console.log(`Violation ${index + 1} Name:`, violation.name);
            } else {
              console.warn(
                `Violation ${index + 1} is missing the 'name' property`,
                violation
              );
            }
          });
          setViolations(data.data);
        } else {
          console.error("Data format error: Expected an array in data.data");
        }
      });
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  useEffect(() => {
    fetchData();
    getAllViolationTypes();

    return () => {
      localStorage.removeItem("token");
    };
  }, []);

  useEffect(() => {
    let table;
    if (tableData.length > 0) {
      table = $("#myTable").DataTable();
    }
    return () => {
      if (table) {
        table.destroy();
      }
    };
  }, [tableData]);

  const handleEditClick = (violationtype, rewardid, rewardpoints) => {
    setViolationType(violationtype);
    setrewardId(rewardid);
    setRewardPoints(rewardpoints);
    setIsUpdateMode(true);
  };

  const handleUpdateClick = async () => {
    try {
      await rewardService.updateBaseReward(rewardId, rewardPoints);
      fetchData();
      setIsUpdateMode(false);
      formRef.current.reset();
    } catch (error) {
      console.error("Error updating reward:", error);
    }
  };
  const createRewardPoints = async () => {
    setIsLoading(true);
    try {
      const rewardData = {
        violationTypeId: selectedViolationTypeId,
        violationType: createViolationType,
        points: createRewardPoint,
      };

      await rewardService.createRewardPoint(rewardData);
      fetchData();
    } catch (error) {
      console.error("Error updating reward:", error);
    } finally {
      setIsLoading(false); // Hide loader after creating violation type
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    console.log(name, value);
    // setFormValues({ ...formValues, [name]: value });
  };

  const handleShow = (e) => {
    setdeleteViolationTypeId(e);
    setShow(true);
  };

  const handleClose = () => setShow(false);
  const handleViolationChange = (e) => {
    const selectedViolationName = e.target.value;
    const selectedViolation = violations.find(
      (v) => v.name === selectedViolationName
    );

    if (selectedViolation) {
      setcreateViolationType(selectedViolationName);
      setSelectedViolationTypeId(selectedViolation.violationTypeId);
    }
  };

  const deleteviolatioType = async () => {
    setIsLoading(true);
    try {
      const response = await rewardService.deleteBaseReward(
        deleteViolationTypeId
      );
      console.log("delete response:", response);
      if (response) {
        fetchData();
      } else {
        console.error("Deletion failed:", response.message);
      }
      setShow(false);
    } catch (error) {
      console.error("Error deleting violation type:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const allFieldsFilled = createRewardPoint;

  return (
    <div className="Container ViolationTypePage">
      <div className="breadcrumbs ms-3">
        <Breadcrumbs aria-label="breadcrumb">
          <Link
            underline="none"
            sx={{ display: "flex", alignItems: "center" }}
            color="inherit"
          >
            <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
            Trrafic Sudharo
          </Link>
          <Link
            underline="none"
            sx={{ display: "flex", alignItems: "center" }}
            color="inherit"
          >
            <EmojiEventsRounded sx={{ mr: 0.5 }} fontSize="inherit" />
            Reward Points
          </Link>
        </Breadcrumbs>
      </div>

      <div className="row">
        <CardsValues />
      </div>

      {isUpdateMode ? (
        <div className="card m-3 updateDetails">
          <div className="card-body update">
            <form ref={formRef}>
              <div className="row">
                <div className="col-xl-3">
                  <div className="mb-0">
                    <label
                      htmlFor="formGroupExampleInput"
                      className="form-label ms-1"
                    >
                      Violation Type
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="formGroupExampleInput"
                      placeholder="Violation Type"
                      name="violationType"
                      value={violationType}
                      onChange={handleInputChange}
                      readOnly
                    />
                  </div>
                </div>
                <div className="col-xl-3">
                  <div className="mb-0">
                    <label
                      htmlFor="formGroupExampleInput"
                      className="form-label ms-1"
                    >
                      Violation ID
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="formGroupExampleInput"
                      placeholder="Violation ID"
                      name="violationId"
                      value={rewardId}
                      onChange={handleInputChange}
                    />
                  </div>
                </div>
                <div className="col-xl-3">
                  <div className="mb-0">
                    <label
                      htmlFor="formGroupExampleInput"
                      className="form-label ms-1"
                    >
                      Reward Points
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="formGroupExampleInput"
                      placeholder="Reward Points"
                      name="points"
                      value={rewardPoints}
                      onChange={(e) => setRewardPoints(e.target.value)}
                    />
                  </div>
                </div>
                <div className="col-xl-3 addbtn mt-4">
                  <button
                    type="button"
                    className="btn btn-warning"
                    onClick={handleUpdateClick}
                  >
                    Update
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      ) : (
        <div className="card m-3 createRewards">
          <div className="card-body">
            <div className="row">
              <div className="col-xl-3">
                <label htmlFor="inputState" className="form-label ms-1">
                  Violation Type
                </label>
                <select
                  id="inputState"
                  className="form-select"
                  onChange={handleViolationChange}
                  value={createViolationType}
                >
                  <option value="" disabled selected>
                    Choose...
                  </option>
                  {violations.map((violation) => (
                    <option key={violation._id} value={violation.name}>
                      {violation.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="col-xl-3">
                <div className="mb-0">
                  <label
                    htmlFor="formGroupExampleInput"
                    className="form-label ms-1"
                  >
                    Violation ID
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="formGroupExampleInput"
                    placeholder="Violation ID"
                    name="violationId"
                    value={selectedViolationTypeId}
                    // onChange={(e) => setCreateViolationId(e.target.value)}
                    readOnly
                  />
                </div>
              </div>
              <div className="col-xl-3">
                <div className="mb-0">
                  <label
                    htmlFor="formGroupExampleInput"
                    className="form-label ms-1"
                  >
                    Reward Points
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="formGroupExampleInput"
                    placeholder="Reward Points"
                    name="points"
                    value={createRewardPoint}
                    onChange={(e) => setCreateRewardPoint(e.target.value)}
                  />
                </div>
              </div>
              <div className="col-xl-3 addbtn mt-4">
                <button
                  type="button"
                  className="btn btn-warning"
                  onClick={createRewardPoints}
                  disabled={!allFieldsFilled}
                >
                  Add
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* table data */}

      {isLoading ? (
        <div className="loader-container">
          <CircularProgress />
        </div>
      ) : (
        <div className="card m-3">
          <div className="card-body">
            <table
              id="myTable"
              className="table table-striped"
              style={{ width: "100%" }}
            >
              <thead>
                <tr>
                  <th className="text-center">Id</th>
                  <th className="text-center">Violation Type</th>
                  <th className="text-center">Reward Points</th>
                  <th className="text-center">Status</th>
                </tr>
              </thead>
              <tbody>
                {tableData.map((row, index) => (
                  <tr key={index}>
                    <td className="text-center">{index + 1}</td>
                    <td className="text-center">{row.violationType}</td>
                    <td className="text-center">{row.points}</td>
                    <td>
                      <div className="d-flex justify-content-center align-items-center">
                        <FontAwesomeIcon
                          icon={faEdit}
                          className="FontAwesomeIcons me-2 text-primary"
                          onClick={() =>
                            handleEditClick(
                              row.violationType,
                              row.rewardId,
                              row.points
                            )
                          }
                        />
                        <FontAwesomeIcon
                          icon={faTrash}
                          className="FontAwesomeIcons ms-1 text-danger"
                          onClick={(e) => handleShow(row.rewardId)}
                        />
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      )}

      <Modal show={show} onHide={handleClose} centered>
        <Modal.Body>
          <h3 className="text-center">Are you sure want to delete</h3>
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            // height="100vh"
          >
            <Stack direction="row" spacing={2}>
              <Button
                variant="contained"
                color="error"
                startIcon={<DeleteIcon />}
                onClick={deleteviolatioType}
              >
                Delete
              </Button>
              <Button
                variant="contained"
                color="primary"
                endIcon={<CancelOutlined />}
                onClick={handleClose}
              >
                Close
              </Button>
            </Stack>
          </Box>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default RewardPoints;

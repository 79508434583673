import React, { useEffect, useState } from "react";
import $ from "jquery";
import "datatables.net-bs5"; // Make sure the path is correct
import "datatables.net-bs5/css/dataTables.bootstrap5.min.css";
import {
  getAllViolations,
  getViolationById,
} from "../Services/violationService";
import CardsValues from "./CardsValues";
import Modal from "react-bootstrap/Modal";
import { Breadcrumbs, Link, Stack } from "@mui/material";
import Button from "@mui/material/Button";
import { CancelOutlined, EditNoteRounded } from "@mui/icons-material";
import userViolationService from "../Services/userViolationService";
import HomeIcon from "@mui/icons-material/Home";
import WhatshotIcon from "@mui/icons-material/Whatshot";

const statusMapping = {
  1000: "Pending",
  1001: "Approved",
  1002: "Reject",
};

// function getImageFormat(base64String) {
//   const startIndex = base64String.indexOf("/") + 1;
//   const endIndex = base64String.indexOf(";");

//   return base64String.substring(startIndex, endIndex);
// }

function SubDashboard() {
  const [tableData, setTableData] = useState([]);
  const [show, setShow] = useState(false);
  const [modeldata, setmodeldata] = useState();
  const [modelStatus, setmodelStatus] = useState();
  const [modelReason, setmodelReason] = useState();
  const [modelUserViolationId, setmodelUserViolationId] = useState();
  const [modelVechileType, setmodelVechileType] = useState();
  const [modelAdminId, setmodelAdminId] = useState();
  const [isReadOnly, setIsReadOnly] = useState(true);
  const [globalImageElement, setGlobalImageElement] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const handleClose = () => setShow(false);
  const handleShow = (e) => {
    setShow(true);
    getByIdOfUserViolation(e);
  };

  const fetchData = async () => {
    setIsLoading(true);
    try {
      const response = await getAllViolations();
      setTableData(response.data);
    } catch (error) {
      console.error("Error fetching data:", error, setIsReadOnly);
    } finally {
      setIsLoading(false);
    }
  };

  const getByIdOfUserViolation = async (e) => {
    try {
      const response = await getViolationById(e);
      console.log("aaaaaaagetbyid" + response.data);

      // const resadminId = localStorage.getItem("adminId");
      if (response && response.data) {
        response.data.forEach((violation) => {
          setmodeldata(violation.vehicleNumber);
          setmodelVechileType(violation.vehicleType);
          setmodelAdminId(localStorage.getItem("adminId"));
          setmodelUserViolationId(violation.userViolationId);
          violation.image=violation.image && violation.image.replace('http://192.168.1.74:9986', 'https://trafficsudharo.com')
          setGlobalImageElement(violation.image);
          setmodelReason(violation.reason);
          setmodelStatus(violation.status);
        });
      } else {
        console.error("Unexpected response format:", response);
      }
      // setShow(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      // setShow(false);
    }
  };

  useEffect(() => {
    fetchData();

    return () => {
      // Remove token from localStorage when component unmounts
      localStorage.removeItem("token");
    };
  }, []);

  useEffect(() => {
    let table;
    if (tableData.length > 0) {
      table = $("#myTable").DataTable();
    }
    return () => {
      if (table) {
        table.destroy();
      }
    };
  }, [tableData]);

  const updateModelPopupData = async () => {
    const updatemodelpopPayload = {
      adminId: modelAdminId,
      userViolationId: modelUserViolationId,
      reason: modelReason,
      vehicleType: modelVechileType,
      vehicleNumber: modeldata,
      status: modelStatus, // 1000 pending,1001 approved,1002 rejected
    };
    try {
      const response = await userViolationService.updateViolationStatus(
        updatemodelpopPayload
      );
      handleClose();

      const updatedTableData = tableData.map((item) =>
        item.userViolationId === modeldata.userViolationId
          ? response.data
          : item
      );
      setTableData(updatedTableData);
    } catch (error) {
      handleClose();
      console.log("failed to update the data......");
    }
  };

  const allFieldsFilled = modelReason && modelVechileType;

  return (
    <div className="Container subdashboard m-0 p-0">
      <div className="breadcrumbs ms-3">
        <Breadcrumbs aria-label="breadcrumb">
          <Link
            underline="none"
            sx={{ display: "flex", alignItems: "center" }}
            color="inherit"
          >
            <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
            Trrafic Sudharo
          </Link>
          <Link
            underline="none"
            sx={{ display: "flex", alignItems: "center" }}
            color="inherit"
          >
            <WhatshotIcon sx={{ mr: 0.5 }} fontSize="inherit" />
            Dashboard
          </Link>
        </Breadcrumbs>
      </div>

      <div className="row">
        <CardsValues />
      </div>

      <div className="card m-3">
        {isLoading ? (
          <div class="container">
            <div class="row">
              <div class="col-sm-12 text-center overlay">
                <div class="loader3">
                  <span></span>
                  <span></span>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="card-body">
            <table
              id="myTable"
              className="table table-striped"
              style={{ width: "100%" }}
            >
              <thead>
                <tr>
                  <th style={{ fontSize: "13px" }}>Violation Type Id</th>
                  <th style={{ fontSize: "13px" }}>Image</th>
                  <th style={{ fontSize: "12px" }}>Violation Type</th>
                  <th style={{ fontSize: "12px" }}>Vehicle Type</th>
                  <th style={{ fontSize: "12px" }}>Vehicle Number</th>
                  <th style={{ fontSize: "13px" }}>Location</th>
                  <th style={{ fontSize: "12px" }}>Issue Date</th>
                  <th style={{ fontSize: "12px" }}>Status</th>
                  <th style={{ fontSize: "12px" }}>Details</th>
                </tr>
              </thead>
              <tbody>
                {tableData.map((row, index) => (
                  <tr key={index}>
                    <td className="text-center">{index + 1}</td>
                    <td>
                      <img
                        // src={`data:image/jpeg;base64,${row.image}`}
                        // src={`
                        //   data:image/${getImageFormat(row.image)};base64,${
                        //   row.image
                        // }`}
                        src={row.image && row.image.replace('http://192.168.1.74:9986', 'https://trafficsudharo.com')}
                        alt="violation"
                        width="50"
                        height="50"
                      />
                    </td>
                    <td>{row.violationType}</td>
                    <td>{row.vehicleType}</td>
                    <td>{row.vehicleNumber}</td>
                    <td>{row.location}</td>
                    <td>{row.createdAt}</td>
                    <td>{statusMapping[row.status] || "Unknown"}</td>
                    <td>
                      <button
                        className="btn btn-outline-primary"
                        onClick={(e) => handleShow(row.userViolationId)}
                      >
                        Verify
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>

            <Modal
              show={show}
              onHide={handleClose}
              backdrop="static"
              keyboard={false}
              size="md"
              aria-labelledby="contained-modal-title-vcenter"
              centered
            >
              <Modal.Header className="center">
                <Modal.Title>Violation Type Details</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="ViolationModelPopup">
                  <div className="row">
                    <div className="col-xl-6 col-md-6 col-sm-6">
                      <img
                        src={globalImageElement}
                        // src={`data:image/${getImageFormat(globalImageElement)};base64,${globalImageElement}`}
                        // src={`data:image/jpeg;base64,${globalImageElement}`}
                        alt="violation"
                        style={{ width: "100%", height: "60%" }}
                      />
                    </div>
                    <div className="col-xl-6 col-md-6 col-sm-6">
                      <div className="vechileNumberInputfield">
                        <label
                          htmlFor="exampleInputEmail1"
                          className="form-label ms-1"
                        >
                          Vechile Number
                        </label>
                        <input
                          type="email"
                          className="form-control"
                          id="exampleInputEmail1"
                          aria-describedby="emailHelp"
                          placeholder="Please enter the VechileNumber"
                          readOnly={isReadOnly}
                          value={modeldata}
                        ></input>
                      </div>
                      <div className="vechileNumberInputfield mt-2">
                        <label
                          htmlFor="exampleInputEmail1"
                          className="form-label ms-1"
                        >
                          Reason
                        </label>
                        <input
                          type="email"
                          className="form-control mt-0"
                          id="exampleInputEmail1"
                          aria-describedby="emailHelp"
                          value={modelReason}
                          placeholder="Please enter the reason"
                          onChange={(e) => setmodelReason(e.target.value)}
                        ></input>
                      </div>
                      <div className="vechileNumberInputfield mt-2">
                        <label
                          htmlFor="exampleInputEmail1"
                          className="form-label ms-1"
                        >
                          Vechile Type
                        </label>
                        <input
                          type="email"
                          className="form-control mt-0"
                          id="exampleInputEmail1"
                          aria-describedby="emailHelp"
                          value={modelVechileType}
                          placeholder="Please enter the VechileType"
                          onChange={(e) => setmodelVechileType(e.target.value)}
                        ></input>
                      </div>
                      <div className="statusdropdown mt-2">
                        <label
                          htmlFor="validationCustom04"
                          className="form-label ms-1"
                        >
                          Status
                        </label>
                        <select
                          className="form-select"
                          id="autoSizingSelect"
                          value={modelStatus}
                          onChange={(e) => setmodelStatus(e.target.value)}
                        >
                          {Object.entries(statusMapping).map(
                            ([code, label]) => (
                              <option key={code} value={code}>
                                {label}
                              </option>
                            )
                          )}
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <div className="d-flex justify-content-center w-100">
                  <Stack direction="row" spacing={2}>
                    <Button
                      variant="contained"
                      color="primary"
                      startIcon={<EditNoteRounded />}
                      onClick={updateModelPopupData}
                      disabled={!allFieldsFilled}
                    >
                      Update
                    </Button>
                    <Button
                      variant="contained"
                      color="error"
                      endIcon={<CancelOutlined />}
                      onClick={handleClose}
                    >
                      Close
                    </Button>
                  </Stack>
                </div>
              </Modal.Footer>
            </Modal>
          </div>
        )}
      </div>
    </div>
  );
}

export default SubDashboard;

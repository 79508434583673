import React, { useState } from "react";
import adminService from "../Services/adminService";
import trafficImage from "../images/trafficimg.png";
import logo from "../images/log.png";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast, Zoom } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const AdminRegister = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [submitted, setSubmitted] = useState(false);
  const navigate = useNavigate();

  const redirectToLogin = (e) => {
    e.preventDefault();
    navigate("/");
  };

  const handleRegister = async (e) => {
    setSubmitted(true);
    if (!username || !password) {
      return;
    }
    try {
      await adminService.register(username, password);
      toast.success("Registration Successful", {
        position: "top-right",
        autoClose: 2500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        transition: Zoom,
      });
    } catch (error) {
      toast.error("Registration failed", {
        position: "top-right",
        autoClose: 2500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        transition: Zoom,
      });
    }
  };

  return (
    <div className="container-fluid m-0 p-0">
      <div className="row">
        <div className="col-xl-7 col-md-7 col-sm-7">
          <img
            className="imageClass"
            src={trafficImage}
            alt="Network Error"
            width="100%"
            height="100%"
          />
        </div>
        <div className="col-xl-5 col-md-5 col-sm-5 d-flex align-items-center justify-content-center p-0">
          <div className="card border border-warning w-75">
            <div className="card-body m-3">
              <div className="col-xl-12 col-md-12 col-sm-12 d-flex justify-content-center m-2">
                <img
                  className="logclass"
                  src={logo}
                  alt="Network Error"
                  width="50%"
                  height="10%"
                />
              </div>
              <div className="d-grid mb-2 d-flex justify-content-center">
                <h3 className="text-primary">Admin Register</h3>
              </div>

              <form noValidate>
                <div className="mb-3 mt-2">
                  <input
                    type="text"
                    className={`form-control ${
                      submitted && !username ? "is-invalid" : ""
                    }`}
                    id="formGroupExampleInput"
                    placeholder="User Name"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                    required
                  />
                  {submitted && !username && (
                    <div className="invalid-feedback">
                      Username is mandatory.
                    </div>
                  )}
                </div>
                <div className="mb-3">
                  <input
                    type="password"
                    className={`form-control ${
                      submitted && !password ? "is-invalid" : ""
                    }`}
                    id="formGroupExampleInput2"
                    placeholder="Password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                  />
                  {submitted && !password && (
                    <div className="invalid-feedback">
                      Password is mandatory.
                    </div>
                  )}
                </div>
                <div className="d-grid gap-2">
                  <button
                    className="btn btn-warning"
                    type="button"
                    onClick={(e) => handleRegister(e)}
                  >
                    Register
                  </button>
                  <button
                    className="btn btn-primary"
                    type="button"
                    onClick={(e) => redirectToLogin(e)}
                  >
                    Login
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default AdminRegister;

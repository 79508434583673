import { Route, Routes } from "react-router-dom";
import "./App.css";
import AdminLogin from "./Components/AdminLogin";
import AdminRegister from "./Components/AdminRegister";
import LayoutPage from "./Components/LayoutPage";
import { useEffect, useState } from "react";
import ProtectedRoute from "./Configuration/ProtectedRoute";
import "react-toastify/dist/ReactToastify.css";

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  console.log(isAuthenticated);
  useEffect(() => {
    const loginStatus = localStorage.getItem("isLogin") === "true";
    setIsAuthenticated(loginStatus);
    console.log(isAuthenticated);
  }, [isAuthenticated]);

  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<AdminLogin />} />
        <Route path="/admin_register" element={<AdminRegister />} />
        <Route
          path="/layout_page/*"
          element={<ProtectedRoute element={LayoutPage} />}
        />
      </Routes>
    </div>
  );
}

export default App;

import React, { useState } from "react";
import adminService from "../Services/adminService";
import trafficImage from "../images/trafficimg.png";
import logo from "../images/log.png";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast, Zoom } from "react-toastify";

const AdminLogin = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [submitted, setSubmitted] = useState(false);
  const [adminId, setadminId] = useState();
  // const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();
    setSubmitted(true);
    console.log(adminId);
    // setIsLoading(true);

    try {
      const response = await adminService.login(username, password);
      // console.log("response aaaaaaaaaaaa" + response.data._id);
      localStorage.setItem("adminId", response.data._id);
      setadminId(response.data._id);
      localStorage.setItem("isLogin", true);
      toast.success("Login Successfully.......", {
        position: "top-right",
        autoClose: 1300,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        transition: Zoom,
      });
      setTimeout(() => {
        navigate("/layout_page/subdashboard_page");
      }, 1800);
    } catch (error) {
      toast.error("Login failed....", {
        position: "top-right",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        transition: Zoom,
      });
    }
  };

  const handleRegister = (e) => {
    e.preventDefault();
    navigate("/admin_register");
  };

  return (
    <div className="container-fluid m-0 p-0">
      <div className="row">
        <div className="col-xl-7 col-md-7 col-sm-7">
          <img
            className="imageClass"
            src={trafficImage}
            alt="Network Error"
            width="100%"
            height="100%"
          />
        </div>
        <div className="col-xl-5 col-md-5 col-sm-5 d-flex align-items-center justify-content-center p-0">
          <div className="card border border-warning w-75">
            <div className="card-body m-3">
              <div className="col-xl-12 col-md-12 col-sm-12 d-flex justify-content-center m-2">
                <img
                  className="logclass"
                  src={logo}
                  alt="Network Error"
                  width="50%"
                  height="10%"
                />
              </div>
              <div className="d-grid mb-4 d-flex justify-content-center">
                <h3 className="text-primary">Admin Login</h3>
              </div>

              <form className="row g-3 needs-validation" noValidate>
                <div className="mb-2 mt-2">
                  <input
                    type="text"
                    className={`form-control ${
                      submitted && !username && "is-invalid"
                    }`}
                    id="formGroupExampleInput"
                    placeholder="User Name"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                    required
                  />
                  {submitted && !username && (
                    <div className="invalid-feedback">
                      Please enter your username.
                    </div>
                  )}
                </div>
                <div className="mb-3">
                  <input
                    type="password"
                    className={`form-control ${
                      submitted && !password && "is-invalid"
                    }`}
                    id="formGroupExampleInput2"
                    placeholder="Password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                  />
                  {submitted && !password && (
                    <div className="invalid-feedback">
                      Please enter your password.
                    </div>
                  )}
                </div>

                <div className="d-grid gap-2">
                  <button
                    className="btn btn-warning"
                    type="button"
                    onClick={(e) => handleLogin(e)}
                  >
                    Login
                  </button>
                  <button
                    className="btn btn-primary"
                    type="button"
                    onClick={(e) => handleRegister(e)}
                  >
                    Register
                  </button>
                </div>
              </form>

              <ToastContainer />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminLogin;

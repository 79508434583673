import axios from "axios";
import API_URL from "../Configuration/Configuration";
// Replace with your actual backend URL

const rewardService = {
  // Create a reward point

  createRewardPoint: async (rewardData) => {
    try {
      const response = await axios.post(
        `${API_URL}/rewards/createRewardPoint`,
        rewardData
      );
      return response.data;
    } catch (error) {
      console.error(
        "Error creating reward point:",
        error.response?.data || error.message
      );
      throw error.response?.data || error;
    }
  },

  // Get all base rewards
  getBaseRewards: async () => {
    try {
      const response = await axios.get(`${API_URL}/rewards/getBaseRewards`);
      return response.data;
    } catch (error) {
      console.error(
        "Error fetching base rewards:",
        error.response?.data || error.message
      );
      throw error.response?.data || error;
    }
  },

  // Update a base reward
  updateBaseReward: async (rewardId, points) => {
    try {
      const response = await axios.post(
        `${API_URL}/rewards/updateBaseRewards`,
        { rewardId, points }
      );
      return response.data;
    } catch (error) {
      console.error(
        "Error updating base reward:",
        error.response?.data || error.message
      );
      throw error.response?.data || error;
    }
  },

  // Delete a base reward
  deleteBaseReward: async (rewardId) => {
    try {
      const response = await axios.delete(
        `${API_URL}/rewards/deleteBaseReward`,
        { data: { rewardId } }
      );
      return response.data;
    } catch (error) {
      console.error(
        "Error deleting base reward:",
        error.response?.data || error.message
      );
      throw error.response?.data || error;
    }
  },
};

export default rewardService;

import React from "react";
import LayoutPage from "./LayoutPage";

function DashboardPage() {
  return (
    <div>
      <LayoutPage />
    </div>
  );
}

export default DashboardPage;
